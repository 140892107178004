/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useRef } from "react";
import axios from "axios";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop, Link } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAlertAnimated from "components/MDAlertAnimated";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import globals from "assets/theme/base/globals";
import TermsModal from "./terms-modal";

//API Endspoint
import API_BASE_URL from "config";

function FarmDataUploader() {
  const fileInputRef = useRef(null); // Reference for file input reset
  const initialFormData = {
    selectedFile: null,
    agreeTerms: false,
  };
  const allowedExtensions = ["xlsx", "xls", "csv", "zip", "shp", "shx", "dbf"];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const token = userData ? userData.token : "";

  const handleChange = (event) => {
    const { type, checked, files, name } = event.target;
    let value = type === "checkbox" ? checked : files[0];
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    //setShowAlert(false);
    //setShowError(false);
    e.preventDefault();
    const validationErrors = validate(formData);
    setFormErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const headers = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.post(
          API_BASE_URL + "/upload_farm_data",
          {
            file: formData.selectedFile,
          },
          { headers }
        );
        if (response.data.success == true) {
          setAlertMessage(response.data.message);
          setShowAlert(true);
          setFormData(initialFormData);
          if (fileInputRef.current) fileInputRef.current.value = "";
        } else {
          setAlertMessage(response.data.message);
          setShowError(true);
        }
        setLoading(false);
      } catch (error) {
        console.log("oops...error", error);
        setAlertMessage(error.response.data.message);
        setShowError(true);
        setLoading(false);
      }
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.selectedFile) {
      errors.selectedFile = "File is required.";
    } else if (values.selectedFile.size > 5 * 1024 * 1024) {
      errors.selectedFile = "Maximum allowed file size is 5MB.";
    } else if (values.selectedFile) {
      const fileExtension = values.selectedFile.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        errors.selectedFile =
          "Invalid file type! Allowed: .xlsx, .xls, .csv, .zip, .shp, .shx, .dbf";
      }
    }
    if (!values.agreeTerms) {
      errors.agreeTerms = "You must agree to the Terms and Conditions.";
    }
    return errors;
  };
  return (
    <BaseLayout>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card id="farm-data-uploader">
                    <MDBox p={3}>
                      {showAlert && <MDAlertAnimated message={alertMessage} color="success" />}
                      {showError && <MDAlertAnimated message={alertMessage} color="error" />}
                      <MDTypography variant="h5" fontWeight="medium">
                        Secure Farm Data Uploader
                      </MDTypography>
                      <MDTypography variant="button" color="text" fontWeight="medium">
                        Please enter your details and upload your Excel file below.
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ ...globals.uploadInfoBox }} mb={2}>
                      <MDTypography variant="h6" color="info" fontWeight="bold">
                        Steps to Upload:
                      </MDTypography>
                      <MDTypography variant="body2" color="info">
                        1. Download the sample template file.
                        <Link fontWeight="bold" href="/sample_template.xlsx" download>
                          &nbsp;Click Here
                        </Link>
                        <br />
                        2. Fill in the required data. <br />
                        3. Upload the completed file using the form below.
                      </MDTypography>
                    </MDBox>
                    <MDBox component="form" pb={3} px={3}>
                      {loading && (
                        <Backdrop
                          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                          open={open}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      )}
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <MDTypography variant="subtitle2" gutterBottom>
                            Select Farm Data File
                          </MDTypography>
                          <MDInput
                            name="selectedFile"
                            type="file"
                            inputRef={fileInputRef} // Attach ref to file input
                            //onChange={handleChange}
                            onChange={(e) =>
                              setFormData({ ...formData, selectedFile: e.target.files[0] })
                            }
                            fullWidth
                            inputProps={{
                              accept: allowedExtensions.join(","), // Restrict file types
                            }}
                          />
                          {formErrors.selectedFile && (
                            <MDTypography display="block" variant="button" color="error" mb={0}>
                              {formErrors.selectedFile}
                            </MDTypography>
                          )}
                        </Grid>
                        <Grid item xs={12} ml={-1}>
                          <Checkbox
                            name="agreeTerms"
                            checked={formData.agreeTerms}
                            //onChange={handleChange}
                            onChange={(e) =>
                              setFormData({ ...formData, agreeTerms: e.target.checked })
                            }
                          />
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                          >
                            &nbsp;&nbsp;I agree the&nbsp;
                          </MDTypography>
                          <MDTypography
                            component="a"
                            href="#"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            onClick={() => setShowTerms(true)}
                            style={{ textTransform: "none" }}
                            textGradient
                          >
                            Terms and Conditions
                          </MDTypography>
                          {formErrors.agreeTerms && (
                            <MDTypography
                              display="block"
                              variant="button"
                              color="error"
                              mb={0}
                              ml={1}
                            >
                              {formErrors.agreeTerms}
                            </MDTypography>
                          )}
                        </Grid>
                      </Grid>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        flexWrap="wrap"
                      >
                        <MDBox ml="auto" mt={1}>
                          <MDButton
                            variant="gradient"
                            onClick={handleSubmit}
                            color="info"
                            size="small"
                          >
                            Upload
                          </MDButton>
                        </MDBox>
                      </MDBox>
                      {/* Terms & Conditions Modal */}
                      <TermsModal open={showTerms} onClose={() => setShowTerms(false)} />
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default FarmDataUploader;
