import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
//API Endspoint
import API_BASE_URL from "config";
import MDButton from "components/MDButton";

const TermsModal = ({ open, onClose }) => {
  const [terms, setTerms] = useState("");

  useEffect(() => {
    axios
      .get(API_BASE_URL + "/terms")
      .then((response) => setTerms(response.data.content))
      .catch((error) => console.error("Error fetching terms:", error));
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Terms & Conditions</DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: terms }} />
        <MDButton onClick={onClose} variant="gradient" color="info" size="small" mt={1}>
          Close
        </MDButton>
      </DialogContent>
    </Dialog>
  );
};

//Add PropTypes validation
TermsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TermsModal;
