import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
// @mui material components
import Icon from "@mui/material/Icon";
import debounce from "lodash.debounce";
import axios from "axios";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDConfirmationAlert from "components/MDConfirmationAlert";
import MDAlertAnimated from "components/MDAlertAnimated";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import API_BASE_URL from "config";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

const FarmDataFileList = () => {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [sortField, setSortField] = useState("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const token = userData ? userData.token : "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  // Define the columns for the DataTable
  const columns = [
    {
      name: "File Name",
      selector: (row) => row.file_name,
      sortable: true,
      sortField: "file_name",
      width: "50%", // Set width to 60%
    },
    {
      name: "Uploaded At",
      selector: (row) => moment(row.created_at).format("DD-MMM-YYYY HH:mm:ss"),
      sortField: "created_at",
      sortable: true,
      width: "30%",
    },
    {
      name: "Download",
      selector: (row) => (
        //<a href={row.file_url} download target="_blank" rel="noopener noreferrer">
        <Icon
          fontSize="small"
          sx={{ color: "#1763CB", cursor: "pointer" }}
          onClick={() => handleDownload(row.id, row.file_name)}
        >
          cloud_download
        </Icon>
        //</a>
      ),
      sortable: false,
      center: true,
      width: "10%",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Icon
            onClick={() => handleDelete(row.id)}
            fontSize="small"
            sx={{ color: "#F44335", cursor: "pointer" }}
          >
            delete
          </Icon>
        </>
      ),
      sortable: false,
      center: true,
      width: "10%",
    },
    // Add more columns as needed
  ];
  const handleDelete = (itemId) => {
    setSelectedItem(itemId);
    setShowConfirmationAlert(true); // Show the alert when delete is triggered
  };
  // Confirm delete action
  const confirmDelete = async () => {
    try {
      setShowAlert(false);
      const response = await axios.delete(`${API_BASE_URL}/farm_data_files/${selectedItem}`, {
        headers,
      });
      setShowConfirmationAlert(false);
      setSelectedItem(null);
      fetchData(); // Refresh the list of items after deletion
      setAlertMessage(response.data.message);
      setShowAlert(true);
    } catch (error) {
      console.error("Error deleting item:", error);
      setShowConfirmationAlert(false);
    }
  };
  // Cancel delete action
  const cancelDelete = () => {
    setSelectedItem(null);
    setShowConfirmationAlert(false); // Close the alert without deleting
  };
  const handleDownload = async (fileId, fileName) => {
    try {
      const response = await axios.get(API_BASE_URL + "/download/" + fileId, {
        responseType: "blob", // Treat response as a binary file
        headers,
      });

      // Create a Blob URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set filename for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } catch (error) {
      console.error("Download failed:", error);
      alert("Failed to download file.");
    }
  };
  // Fetch data from the API with server-side processing
  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await axios.get(API_BASE_URL + "/farm_data_files", {
        params: {
          page,
          per_page: perPage,
          search: searchText,
          sort_field: sortField,
          sort_direction: sortDirection,
        },
        headers,
      });
      setData(response.data.data.data);
      setTotalRows(response.data.data.total); // Total rows for pagination
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setLoading(false);
  };
  // Handle search
  const handleSearch = (e) => {
    setSearchText(e.target.value);
    setPage(1); // Reset to the first page on search
  };

  // Handle sort
  const handleSort = (column, sortDirection) => {
    setSortField(column.sortField || "id");
    setSortDirection(sortDirection);
  };

  // Handle page change
  const handlePageChange = (page) => {
    setPage(page);
  };

  // Handle rows per page change
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  // Fetch data on load, and when any of the dependencies change
  useEffect(() => {
    fetchData();
  }, [page, perPage, searchText, sortField, sortDirection]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={3}>
        {showAlert && <MDAlertAnimated message={alertMessage} color="success" />}
        {showConfirmationAlert && (
          <MDConfirmationAlert
            title="Are you sure?"
            message="This action cannot be undone!"
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
          />
        )}
        <Card sx={{ pt: 3, pb: 3, px: 3 }}>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <MDTypography variant="h5" fontWeight="medium">
              List of uploaded files
            </MDTypography>
            <MDInput
              placeholder="Search..."
              value={searchText}
              size="small"
              fullWidth
              onChange={handleSearch}
              sx={{ width: "12rem", pr: 2 }}
            />
          </MDBox>
          <DataTable
            //title="Server-Side DataTable"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={handleSort}
            //selectableRows
            highlightOnHover
            sortServer
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default FarmDataFileList;
