/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";

function BasicInfo() {
  const [userProfile, setUserProfile] = useState(getUserData());
  function getUserData() {
    // getting stored userProfile Data
    const temp = sessionStorage.getItem("userData");
    const storesUserData = JSON.parse(temp);
    return storesUserData || [];
  }
  useEffect(() => {
    // storing data
    const temp = JSON.stringify(userProfile);
    sessionStorage.setItem("userData", temp);
  }, [userProfile]);
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              name="firstname"
              label="First Name"
              placeholder="Alec"
              value={userProfile.first_name}
              readOnly
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="lastname"
              label="Last Name"
              placeholder="Thompson"
              value={userProfile.last_name}
              readOnly
            />
          </Grid>
          <Grid item xs={12} sm={userProfile.company ? 6 : 12}>
            <FormField
              name="email"
              label="Email"
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
              value={userProfile.email}
              readOnly
            />
          </Grid>
          {userProfile.company && (
            <Grid item xs={12} sm={6}>
              <FormField
                name="company"
                label="Company"
                placeholder="ABC Company"
                inputProps={{ type: "text" }}
                value={userProfile.company}
                readOnly
              />
            </Grid>
          )}
          {/* <Grid item xs={12} sm={6}>
            <FormField label="your location" placeholder="Sydney, A" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Phone Number"
              placeholder="+40 735 631 620"
              inputProps={{ type: "number" }}
            />
          </Grid> */}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
