import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";
import PrivateRoutes from "utils/PrivateRoutes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";

import Analytics from "layouts/dashboards/analytics";
import PowerBiReport from "layouts/dashboards/PowerBiReport";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import FarmDataUploader from "layouts/pages/farm-data-uploader/add";
import FarmDataFileList from "layouts/pages/farm-data-uploader/list";
import SignInCover from "layouts/authentication/sign-in";
import SignUpCover from "layouts/authentication/sign-up";
import ResetCover from "layouts/authentication/reset-password";
import ForgetCover from "layouts/authentication/forget-password";
import { ReportConfigProvider } from "ReportConfigContext";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userToken = userData ? userData.token : "";
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.access == "private") {
          return (
            <Route key="" element={<PrivateRoutes />}>
              <Route exact path={route.route} element={route.component} key={route.key} />
            </Route>
          );
        } else {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
      }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <ReportConfigProvider>
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName=""
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {/* {configsButton} */}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {/* {getRoutes(routes)} */}
            <Route element={<PrivateRoutes />}>
              <Route element={<Settings />} path="/user-profile" />
              <Route element={<FarmDataUploader />} path="/farm-data-uploader" />
              <Route element={<FarmDataFileList />} path="/view-uploaded-files" />
              <Route element={<Analytics />} path="/dashboards/analytics" />
              <Route element={<PowerBiReport />} path="/:slug/:pageId?" />
            </Route>
            <Route
              element={userToken ? <Navigate to="/user-profile" /> : <SignInCover />}
              path="/sign-in"
            />
            <Route
              element={userToken ? <Navigate to="/user-profile" /> : <SignUpCover />}
              path="/sign-up"
            />
            <Route
              element={userToken ? <Navigate to="/user-profile" /> : <ResetCover />}
              path="/reset-password/:email/:token"
            />
            <Route
              element={userToken ? <Navigate to="/user-profile" /> : <ForgetCover />}
              path="/forget-password"
            />
            <Route
              path="*"
              element={userToken ? <Navigate to="/user-profile" /> : <Navigate to="/sign-in" />}
            />
          </Routes>
        </ThemeProvider>
      </CacheProvider>
    </ReportConfigProvider>
  ) : (
    <ReportConfigProvider>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName=""
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {/* {getRoutes(routes)} */}
          <Route element={<PrivateRoutes />}>
            <Route element={<Settings />} path="/user-profile" />
            <Route element={<FarmDataUploader />} path="/farm-data-uploader" />
            <Route element={<FarmDataFileList />} path="/view-uploaded-files" />
            <Route element={<Analytics />} path="/dashboards/analytics" />
            <Route element={<PowerBiReport />} path="/:slug/:pageId?" />
          </Route>
          <Route
            element={userToken ? <Navigate to="/user-profile" /> : <SignInCover />}
            path="/sign-in"
          />
          <Route
            element={userToken ? <Navigate to="/user-profile" /> : <SignUpCover />}
            path="/sign-up"
          />
          <Route
            element={userToken ? <Navigate to="/user-profile" /> : <ResetCover />}
            path="/reset-password/:email/:token"
          />
          <Route
            element={userToken ? <Navigate to="/user-profile" /> : <ForgetCover />}
            path="/forget-password"
          />
          <Route
            path="*"
            element={userToken ? <Navigate to="/user-profile" /> : <Navigate to="/sign-in" />}
          />
        </Routes>
      </ThemeProvider>
    </ReportConfigProvider>
  );
}
