import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MDAlert from "components/MDAlert";
import "./MDAlertAnimated.css"; // Import CSS for styling and animation

function MDAlertAnimated({ color, message, onClose }) {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
      if (onClose) onClose();
    }, 5000); // Auto dismiss after 5 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <>
      {showAlert && (
        <div className="animated-mdalert">
          <MDAlert color={color} onClose={() => setShowAlert(false)} dismissible>
            {message}
          </MDAlert>
        </div>
      )}
    </>
  );
}

// Add PropTypes for prop validation
MDAlertAnimated.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  message: PropTypes.string.isRequired, // message must be a string and is required
  onClose: PropTypes.func, // onClose is optional
};
// Default props for optional props
MDAlertAnimated.defaultProps = {
  onClose: null,
};

export default MDAlertAnimated;
