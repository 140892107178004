import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import "./MDConfirmationAlert.css";

function MDConfirmationAlert({ title, message, onConfirm, onCancel }) {
  return (
    <SweetAlert
      warning
      showCancel
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="secondary"
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      focusCancelBtn
    >
      {message}
    </SweetAlert>
  );
}

MDConfirmationAlert.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default MDConfirmationAlert;
