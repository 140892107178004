import { useEffect, useState } from "react";
import axios from "axios";

// react-router-dom components
import { useLocation, useNavigate, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

//API Endspoint
import API_BASE_URL from "config";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
//import dynamic report data
import { useReportConfig } from "ReportConfigContext";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { reportConfig } = useReportConfig();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  let textColor = "white";

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userFullName = userData ? userData.full_name : "";
  const userType = userData ? userData.user_type : "";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);
  const navigate = useNavigate();
  const triggerLogout = () => {
    // Set up the request headers with the token
    const token = userData ? userData.token : "";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    // Make the logout request
    axios
      .post(API_BASE_URL + "/logout", null, { headers })
      .then((response) => {
        // Handle successful logout
        sessionStorage.clear();
        navigate("/sign-in");
      })
      .catch((error) => {
        // Handle error
        console.error("Logout error:", error);
      });
  };

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse
      .filter((item) => item.usertype.includes(userType)) // Filter by usertype
      .map(({ name, route, key, href }) =>
        href ? (
          <Link
            key={key}
            href={href}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} nested />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={route === pathname} nested />
          </NavLink>
        )
      );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses
      .filter((item) => item.usertype.includes(userType)) // Filter by usertype
      .map(({ name, collapse, route, href, key }) => {
        let returnValue;

        if (collapse) {
          returnValue = (
            <SidenavItem
              key={key}
              variant="gradient"
              color={color}
              name={name}
              active={key === itemParentName ? "isParent" : false}
              open={openNestedCollapse === key}
              onClick={({ currentTarget }) =>
                openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(key)
              }
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          );
        } else {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavItem variant="gradient" color={color} name={name} active={key === itemName} />
            </Link>
          ) : key === "logout" ? (
            <NavLink
              onClick={(e) => {
                e.preventDefault();
                triggerLogout();
              }}
              key={key}
              sx={{ textDecoration: "none" }}
            >
              <SidenavItem variant="gradient" color={color} name={name} active={key === itemName} />
            </NavLink>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <SidenavItem variant="gradient" color={color} name={name} active={key === itemName} />
            </NavLink>
          );
        }
        return <SidenavList key={key}>{returnValue}</SidenavList>;
      });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes
    .filter((item) => item.usertype.includes(userType)) // Filter by usertype
    .map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else if (key === "profile" && userFullName !== null) {
          returnValue = (
            <SidenavCollapse
              key={userFullName.replace("", "-")}
              name={userFullName}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        if (reportConfig.length == 0 && key == "azure-reports-title") {
          returnValue = "";
        } else {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        }
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      } else if (type === "dynamicreport") {
        // dynamic routes based on reparts and pages for allowed azure function for subscription type
        returnValue =
          reportConfig &&
          reportConfig.map((azurefunction) =>
            azurefunction.pages.length > 0 ? (
              <div key={`nav_${azurefunction.report_slug}`}>
                <SidenavCollapse
                  key={azurefunction.report_slug}
                  name={azurefunction.function_name}
                  icon={<Icon fontSize="medium">image</Icon>}
                  active={azurefunction.report_slug === collapseName}
                  open={openCollapse === azurefunction.report_slug}
                  onClick={() =>
                    openCollapse === azurefunction.report_slug
                      ? setOpenCollapse(false)
                      : setOpenCollapse(azurefunction.report_slug)
                  }
                >
                  {azurefunction.pages && (
                    <SidenavList key="pages">
                      {azurefunction.pages.map((item) => (
                        <NavLink
                          to={`/${azurefunction.report_slug}/${item.name}`}
                          key={item.name}
                          sx={{ textDecoration: "none" }}
                        >
                          <SidenavItem
                            variant="gradient"
                            color={color}
                            name={item.displayName}
                            active={item.name === itemName}
                          />
                        </NavLink>
                      ))}
                    </SidenavList>
                  )}
                </SidenavCollapse>
                <Divider
                  light={
                    (!darkMode && !whiteSidenav && !transparentSidenav) ||
                    (darkMode && !transparentSidenav && whiteSidenav)
                  }
                />
              </div>
            ) : (
              <NavLink to={`/${azurefunction.report_slug}`} key={azurefunction.report_slug}>
                <SidenavCollapse
                  name={azurefunction.function_name}
                  icon={<Icon fontSize="medium">image</Icon>}
                  noCollapse={true}
                  active={azurefunction.report_slug === collapseName}
                  open={openCollapse === azurefunction.report_slug}
                  onClick={() =>
                    openCollapse === azurefunction.report_slug
                      ? setOpenCollapse(false)
                      : setOpenCollapse(azurefunction.report_slug)
                  }
                ></SidenavCollapse>
                <MDBox>
                  <Divider
                    light={
                      (!darkMode && !whiteSidenav && !transparentSidenav) ||
                      (darkMode && !transparentSidenav && whiteSidenav)
                    }
                  />
                </MDBox>
              </NavLink>
            )
          );
      }

      return returnValue;
    });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="10rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
