/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  11. The `component` key is used to store the component of its route.
  12. The `type` key with the `dynamicreport` value is used for a route for repors and pages nased on azure function.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import PowerBiReport from "layouts/dashboards/PowerBiReport";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import FarmDataUploader from "layouts/pages/farm-data-uploader/add";
import FarmDataFileList from "layouts/pages/farm-data-uploader/list";
import SignInCover from "layouts/authentication/sign-in";
import SignUpCover from "layouts/authentication/sign-up";
import ResetCover from "layouts/authentication/reset-password";
import ForgetCover from "layouts/authentication/forget-password";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/profile.jpg";
const routes = [
  {
    type: "collapse",
    access: "private",
    name: "Profile",
    key: "profile",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    usertype: ["market", "farm"],
    collapse: [
      {
        name: "My Profile",
        access: "private",
        key: "user-profile",
        route: "/user-profile",
        component: <Settings />,
        usertype: ["market", "farm"],
      },
      {
        name: "Logout",
        access: "private",
        key: "logout",
        route: "/sign-in",
        component: <SignInCover />,
        usertype: ["market", "farm"],
      },
    ],
  },
  { type: "title", title: "Farm Data", key: "farm", usertype: ["farm"] },
  { type: "divider", key: "divider-a", usertype: ["farm"] },
  {
    type: "collapse",
    name: "Farm Data Uploader",
    access: "private",
    key: "farm-data-uploader",
    route: "/farm-data-uploader",
    component: <FarmDataUploader />,
    icon: <Icon fontSize="medium">cloud_upload</Icon>,
    noCollapse: true,
    usertype: ["farm"],
  },
  {
    type: "collapse",
    name: "View Uploaded Files",
    access: "private",
    key: "view-uploaded-files",
    route: "/view-uploaded-files",
    component: <FarmDataFileList />,
    icon: <Icon fontSize="medium">list_alt</Icon>,
    noCollapse: true,
    usertype: ["farm"],
  },
  { type: "title", title: "Reports", key: "azure-reports-title", usertype: ["farm"] },
  { type: "divider", key: "divider-0", usertype: ["market", "farm"] },
  {
    type: "dynamicreport",
    access: "private",
    name: "Azure Reports",
    key: "az-report",
    usertype: ["market", "farm"],
  },
];

export default routes;
